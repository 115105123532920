<template>
  <div style="height: calc(100% - 150px);">
    <p class="class-name">{{ class_name }}</p>
    <div style="margin-top: 8px;width: 100%;height: 100%;overflow-y: auto;">
      <el-menu>
        <el-submenu v-for="(session,session_index) in class_catalogues" :index="''+session_index" :key="session_index">
          <template slot="title">
            <p>{{ session.show_pos + '. ' + session.session_info.session_name }}</p>
          </template>
          <el-menu-item v-for="(content,content_index) in session.contents" :index="''+session_index+'-'+content_index"
                        :key="content_index"
                        @click="goShowSpecialIndexContent(session,session_index,content,content_index)">
            <template slot="title">
              <div style="display: flex;flex-direction: row;align-items: center">
                <div v-if="content.answer_counts>0" style="padding-right: 10px;box-sizing: border-box">
                  <p v-if="content.answer_counts<content.quiz_ids.length" class="Answering">Answering</p>
                  <p v-else class="Answered">Answered</p>
                </div>
                <p style="flex: 1">
                  {{
                    content.show_pos + ' ' + content.content_info.content_name
                  }}
                </p>
              </div>
            </template>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>

export default {
  name: "ExamNavMenuView",
  props: {
    class_name: {
      type: String,
      default: ''
    },
    class_catalogues: {
      type: Array,
      default: []
    }
  },
  methods: {

    showAnswerInfo(content) {
      let answer_counts = content.answer_counts
      if (answer_counts <= 0) {
        return ''
      }
      let quiz_ids = content.quiz_ids
      if (answer_counts >= quiz_ids.length) {
        return '已答'
      }
      return '答题中'
    },

    goShowSpecialIndexContent(session, session_index, content, content_index) {
      let obj = {
        session: session,
        session_index: session_index,
        content: content,
        content_index: content_index,
      }
      this.$emit("showSpecialQuestion", obj);
    }
  }
}
</script>

<style scoped lang="less">


.Answering {
  text-align: center;
  width: 78px;
  line-height: 30px;
  height: 30px;
  background: #B9CFFF;
  border-radius: 16px;
  font-size: 12px;
  // font-family: AlibabaPuHuiTi-2-65-Medium;
  font-weight: 500;
  color: #092E7F;
}

.Answered {
  text-align: center;
  width: 78px;
  line-height: 30px;
  height: 30px;
  background: #F0F0F0;
  border-radius: 16px;
  font-size: 12px;
  // font-family: AlibabaPuHuiTi-2-65-Medium;
  font-weight: 500;
  color: #9B9B9B;
}

.class-name {
  width: 100%;
  padding: 20px 0px;
  box-sizing: border-box;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.08);
  font-size: 18px;
  // font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: 600;
  color: #333333;
}

/deep/ .el-submenu__title {
  font-size: 16px;
  // font-family: AlibabaPuHuiTi-2-65-Medium;
  font-weight: 500;
  color: #666666;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.08);
}

/deep/ .el-submenu.is-opened .el-submenu__title {
  color: #092E7F;
  background: #F0F5FF;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.08);
}

.el-menu-item {
  //line-height: 78px !important;
  color: #666666 !important;
  //height: 78px !important;
  //box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.04) !important;
}

.el-menu-item.is-active {
  color: #082D7D !important;
  background-color: rgba(240, 245, 255, 1) !important;
}
</style>
