<template>
  <div class="option-container">
    <div
      style="
        width: 100%;
        height: 100%;
        padding: 30px 40px;
        box-sizing: border-box;
      "
    >
      <div
        style="
          align-items: center;
          margin-bottom: 15px;
          display: flex;
          flex-direction: row-reverse;
        "
        v-if="user_type == 'student'"
      >
        <img
          v-if="collection_info != null && collection_info.quiz_id != null"
          src="../../../assets/image/icon_collect.png"
          style="width: 28px; height: auto; cursor: pointer; height: 28px"
          @click="doCollection('remove_collection')"
        />
        <img
          v-else
          src="../../../assets/image/icon_uncollect.png"
          style="cursor: pointer; width: 28px; height: 28px"
          @click="doCollection('collection')"
        />

        <p
          v-if="reviewOption == true"
          class="ai-answer-btn"
          @click="requestAIQuestion"
        >
        AI Solution
        </p>
      </div>
      <div style="display: flex; flex-direction: row">
        <p class="quiz-index">{{ quiz_pos }}</p>
        <p
          class="question-title"
          style="padding-left: 20px; flex: 1; box-sizing: border-box"
        >
          {{ question_title }}
        </p>
      </div>
      <div style="margin-top: 20px">
        <div
          v-for="(option, index) in options"
          :key="index"
          @click="clickOption(option, index)"
          style="cursor: pointer"
        >
          <div style="display: flex; flex-direction: row; margin-bottom: 20px">
            <p class="option_index" :style="customOptionStyle(option)">
              {{ formatOptionIndexToString(index) }}
            </p>
            <div
              class="option-item"
              :style="customOptionStyle(option)"
              style="
                flex: 1;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
              "
            >
              <p style="flex: 1">{{ option.value }}</p>
              <div v-if="option.reviewOption">
                <img
                  src="../../../assets/image/answer_correct.png"
                  style="width: 24px; height: 24px"
                  v-if="option.styleType == 'success'"
                />
                <img
                  src="../../../assets/image/answer_error.png"
                  style="width: 24px; height: 24px"
                  v-else-if="option.styleType == 'failure'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          margin-top: 50px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        "
      >
        <p class="last-question-btn" @click="goLastQuestion">
          Previous question
        </p>
        <div style="width: 25px"></div>
        <p
          class="next-question-btn"
          v-if="reviewOption == true"
          @click="goNextQuestion"
        >
          Next Question
        </p>
        <p class="next-question-btn" v-else @click="gotoSubmitAnswer">
          Submit Answer
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserType,getUserId } from "../../../utils/store";
import Cookie from "js-cookie";

export default {
  name: "ExamOptionView",
  props: {
    quizInfo: {
      type: Object,
      default: {},
    },
    quiz_index: {
      type: Number,
      default: 0,
    },
    quiz_pos: {
      type: String,
      default: "",
    },
    collection_info: {
      type: Object,
      default: {},
    },
  },
  watch: {
    quizInfo: {
      handler(newVal, oldVla) {
        this.formatQuizInfo(newVal);
      },
      immediate: true,
    },
  },
  data() {
    return {
      user_id:getUserId(),
      user_type: getUserType(),
      question_title: "",
      reviewOption: false,
      options: [],
      question_answer: 0,
      answerCorrect:false
    };
  },
  methods: {
    requestAIQuestion() {
      let answer = this.question_answer - 1
      let temp_options = []
      for (let i = 0; i < this.options.length; i++) {
        temp_options.push(this.options[i].value)
      }
      Cookie.set("question_title", this.question_title);
      Cookie.set("answer", answer);
      Cookie.set("options", JSON.stringify(temp_options));
      this.$router.push("/chatai");
    },

    customOptionStyle(option) {
      let style = {};
      if (option.reviewOption) {
        let styleType = option.styleType;
        if (styleType == "normal") {
          style.background = "#FFFFFF";
          style.color = "#092E7F";
          style.borderColor = "#EEEEEE";
        } else if (styleType == "failure") {
          style.background = "#FFF5F5";
          style.color = "#FF0000";
          style.borderColor = "#FF0000";
        } else if (styleType == "success") {
          style.background = "#E4FAF7";
          style.borderColor = "#23B29C";
          style.color = "#23B29C";
        }
      } else {
        if (option.selected) {
          style.background = "#092E7F";
          style.borderColor = "#EEEEEE";
          style.color = "#FFFFFF";
        } else {
          style.background = "#FFFFFF";
          style.color = "#092E7F";
          style.borderColor = "#EEEEEE";
        }
      }
      return style;
    },
    goLastQuestion() {
      let last_quiz_index = this.quiz_index - 1;

      if (last_quiz_index < 0){

      }else{
        this.reset();
      }

      this.$emit("showReviewQuestion", {
        type: "last",
        quiz_index: last_quiz_index,
      });
    },
    goNextQuestion() {
      this.reset();
      let next_quiz_index = this.quiz_index + 1;
      this.$emit("showReviewQuestion", {
        type: "next",
        quiz_index: next_quiz_index,
      });
    },
    reset() {
      this.options = [];
      this.question_title = "";
    },

    gotoSubmitAnswer() {
      this.$emit("submitAnswer");
    },
    doCollection(type) {
      this.$emit("exchangeCollection", { type: type });
    },
    clickOption(option, index) {
      if (option.reviewOption) {
        return;
      }
      let selected = option.selected;
      for (let i = 0; i < this.options.length; i++) {
        this.options[i].selected = false;
      }
      this.options[index].selected = !selected;
    },
    formatQuizInfo(newVal) {
      this.resetData();
      this.question_title = newVal.question_title;
      this.question_answer = newVal.question_answer;
      var correct = false;
      var answer = "";

      if (newVal.quiz_record != null) {
        this.reviewOption = true;
        this.correct = newVal.quiz_record.correct;
        answer = newVal.quiz_record.answer;
        correct = newVal.quiz_record.correct;
      }
      this.answerCorrect = correct
      let temp_options = newVal.question_options;
      for (let i = 0; i < temp_options.length; i++) {
        let item = temp_options[i];
        let obj = {
          selected: false,
          reviewOption: this.reviewOption,
          value: item,
        };
        if (this.reviewOption) {
          let styleType = this.getOptionStyleType(
            i + 1,
            answer,
            this.question_answer,
            correct
          );
          obj["styleType"] = styleType;
        }
        this.options.push(obj);
      }
    },
    formatOptionIndexToString(index) {
      switch (index) {
        case 0:
          return "A";
        case 1:
          return "B";
        case 2:
          return "C";
        case 3:
          return "D";
        case 4:
          return "E";
        case 5:
          return "F";
        case 6:
          return "G";
        case 7:
          return "H";
      }
    },

    // //normal success ,failure
    getOptionStyleType(index, answer, question_answer, correct) {
      //还要显示正确答案
      var styleType = "normal";
      if (correct) {
        if (index == answer) {
          styleType = "success";
        }
      } else {
        if (index == question_answer) {
          styleType = "success";
        } else if (index == answer) {
          styleType = "failure";
        }
      }
      return styleType;
    },
    resetData() {
      this.reviewOption = false;
      this.question_title = "";
      this.options = [];
      this.answerCorrect = false
    },

    getSelectAnswer() {
      if (this.reviewOption) {
        this.$message.error("Already Answered!");
        return;
      }
      let answer = 0;
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].selected) {
          answer = i + 1;
        }
      }
      if (answer <= 0) {
        this.$message.error("Please Select ~");
        return null;
      }
      var right = false;
      if (answer == this.question_answer) {
        right = true;
      }
      return { answer: answer, right: right };
    },
  },
};
</script>

<style scoped lang="less">
.option-container {
  width: 100%;
  height: 100%;
  background: #eff4ff;
}

.quiz-index {
  text-align: center;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: #ffa900;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}

.option_index {
  margin-right: 20px;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  font-size: 14px;
  // font-family: AlibabaPuHuiTi-2-65-Medium;
  font-weight: normal;
  color: #092e7f;
}

.question-title {
  font-size: 16px;
  // font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: bold;
  color: #333333;
  line-height: 28px;
}

.option-item {
  padding: 13px 20px;
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  font-size: 14px;
  // font-family: AlibabaPuHuiTi-2-65-Medium;
  font-weight: normal;
  color: #092e7f;
  border: 1px solid #eeeeee;
}

.option-item:last-child {
  margin-bottom: 0px;
}

.last-question-btn {
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  width: 180px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #FF6450;
  font-size: 14px;
  // font-family: AlibabaPuHuiTi-2-65-Medium;
  font-weight: normal;
  color: #FF6450;
}

.next-question-btn {
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  width: 180px;
  height: 40px;
  background: #FF6450;
  font-size: 14px;
  // font-family: AlibabaPuHuiTi-2-65-Medium;
  font-weight: normal;
  color: #ffffff;
}

.ai-answer-btn {
  margin-right: 15px;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  padding: 0px 20px;
  height: 40px;
  background: #FF6450;
  font-size: 14px;
  // font-family: AlibabaPuHuiTi-2-65-Medium;
  font-weight: normal;
  color: #ffffff;
}
</style>
